import React, { useEffect, useState } from "react";
import '../../App.css';
import Layout from "../Layout";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
    const [expertise, setExpertises] = useState([]);
    const [folk, setFolks] = useState([]);
    const [projects, setProjects] = useState([]);
    const [clients, setClients] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/data/expertise.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(expertise_data => {
                setExpertises(expertise_data);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });

        fetch('/data/folks.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(folk_data => {
                setFolks(folk_data);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });

        fetch('/data/projects.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(projects_data => {
                setProjects(projects_data);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });

        fetch('/data/clients.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(clients_data => {
                setClients(clients_data);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    }, []); 

    const handleProjectClick = (projectItem) => {
        navigate(`/project/${projectItem.id}`, { state: { projectItem } });
    }

    return (
        <>
            <Layout containerClass="about-container" />
            <div className="about">
                <div className="title-container">
                    <h2 className="title">About Kelanara</h2>
                </div>
                <div className="description-container">
                    <p className="description">Selamat datang di Kelanara!👋</p>
                    <p className="description">Kami adalah tim penuh semangat yang mencintai dunia film dan segala keajaiban yang dihadirkannya. Di Kelanara, kami tidak hanya berfokus pada penulisan dan promosi, tetapi juga berkomitmen untuk menciptakan film yang menghibur, menyentuh hati, dan menginspirasi penonton.</p>
                    <p className="description">Kami percaya bahwa setiap cerita memiliki keunikan serta gaya magis tersendiri, dan di dalamnya kami berperan. Tim kami terdiri dari penulis kreatif, sutradara berbakat, dan pemasar andal yang siap menjelajahi berbagai cara untuk menghidupkan beragam ide cemerlang.</p>
                    <p className="description">Kami membuka pintu lebar pula bagi para talenta, baik yang baru maupun yang sudah berpengalaman, untuk berkolaborasi mencipta karya segar dan menarik. Bersama-sama, kita akan menghadirkan cerita menghibur dan memperkaya jiwa.</p>
                    <p className="description">Bergabunglah dengan kami di Kelanara, tempat di mana cerita menjadi hidup dan memberikan makna bagi semua yang menyaksikannya. Mari berkarya, menginspirasi, dan membawa cerita yang menghubungkan kita semua!</p>
                </div>
            </div>
            <div className="expertise">
                <div className="expertise-wrapper">
                    <div className="text">
                        <h2 className="title">Expertise</h2>
                    </div>
                    <div className="all-expertise">
                        {expertise.map((expertiseItem, index) => (
                            <div key={index} className="expertise-item">
                                <p className="expert-title">{expertiseItem.title}</p>
                                <p className="expert-desc">{expertiseItem.desc}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="folks">
                <h2 className="text">Folks</h2>
                <div className="all-folks">
                    {folk.map((folkItem, index) => (
                        <div key={index} className="folk-item">
                            <img src={folkItem.img} alt={folkItem.name} className="image" />
                            <p className="folk-title">{folkItem.name}<br />{folkItem.title}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="project-wrapper">
                <div className="projects-grid">
                    {projects.slice(0, 3).map((project, index) => (
                        <div 
                            key={index} 
                            className="project-item"
                            onClick={() => handleProjectClick(project)}
                        >
                            <img src={project.poster} alt={project.title} className="project-image" />
                            <div className="overlay">
                                <h3 className="project-title">{project.title}</h3>
                                <div className="see-now">
                                    <span>See Now</span>
                                    <img 
                                        src="/icon/see now.png" 
                                        alt="See Now Icon" 
                                        className="icon"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="clients">
                <div className="title">
                    <h2 className="text">Our Clients</h2>
                </div>
                <div className="client-logos">
                    <div className="row-top">
                        {clients.slice(0, 3).map((client, index) => (
                            <img key={index} src={client.logo} alt={client.name} className="client-logo" />
                        ))}
                    </div>
                    <div className="row-bottom">
                        {clients.slice(3, 5).map((client, index) => (
                            <img key={index} src={client.logo} alt={client.name} className="client-logo" />
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AboutUs;