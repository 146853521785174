import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Aboutbutton from "../Aboutbutton";
import TypingEffect from "../../effects/TypingEffect";
import '../../App.css';
import Layout from "../Layout";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [videos, setVideos] = useState([]);
    const [projects, setProjects] = useState([]);
    const [clients, setClients] = useState([]);
    const [news, setNews] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/data/youtube-videos.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(videos_data => {
                setVideos(videos_data);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });

        fetch('/data/projects.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(projects_data => {
                setProjects(projects_data);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });

        fetch('/data/clients.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(clients_data => {
                setClients(clients_data);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });

        fetch('/data/news.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(news_data => {
                setNews(news_data);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });     
    }, []);

    const handlePlay = () => {
        setIsPlaying(true);
    };

    const handleNext = () => {
        setCurrentVideoIndex((prev) => (prev + 1) % videos.length);
        setIsPlaying(false);
    };

    const handlePrev = () => {
        setCurrentVideoIndex((prev) => (prev - 1 + videos.length) % videos.length);
        setIsPlaying(false);
    };

    const handleProjectClick = (projectItem) => {
        navigate(`/project/${projectItem.id}`, { state: { projectItem } });
    }

    const handleNewsClick = (newsItem) => {
        navigate(`/media/${newsItem.id}`, { state: { newsItem } }); // Pass data via state
    };

    return (
        <>
            <Layout containerClass="home-container">
                <div className="home-headline">
                    <h1 className="title">Kelanara Studio</h1>
                    <h3 className="slogan">
                        <TypingEffect text="Menulis Rahasia yang Hanya Ditemukan dalam Kegelapan" speed={100} />
                    </h3>
                </div>
            </Layout>
            <div className="home-about">
                <div className="title-container">
                    <h2 className="title">About</h2>
                    <h2 className="title">Kelanara</h2>
                </div>
                <div className="description-container">
                    <p className="description">Selamat datang di Kelanara!</p>
                    <p className="description">Kami adalah tim penuh semangat yang mencintai dunia film dan segala keajaiban yang dihadirkannya. Di Kelanara, kami tidak hanya berfokus pada penulisan dan promosi, tetapi juga berkomitmen untuk menciptakan film yang menghibur, menyentuh hati, dan menginspirasi penonton.</p>
                    <Aboutbutton />
                </div>
            </div>
            <div className="home-video">
                <div className="video-wrapper">
                    {!isPlaying ? (
                        <div className="thumbnail-container">
                            <img 
                                src={`https://img.youtube.com/vi/${videos[currentVideoIndex]?.id}/maxresdefault.jpg`} 
                                alt="Video Thumbnail" 
                                className="video-thumbnail"
                            />
                            <div className="play-button" onClick={handlePlay}>
                                <img 
                                    src="/icon/play button.png" 
                                    alt="Play Button" 
                                    className="play-icon"
                                />
                            </div>
                        </div>
                    ) : (
                        <iframe
                            src={`https://www.youtube.com/embed/${videos[currentVideoIndex]?.id}?autoplay=1&modestbranding=1&showinfo=0&controls=1&rel=0&fs=1&color=white&disablekb=1&playsinline=1&iv_load_policy=3&enablejsapi=1`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            className="intro playing"
                        ></iframe>
                    )}
                    <div className="bottom-wrapper">
                        <div className="text-wrapper">
                            <h4 className="video-text">{videos[currentVideoIndex]?.title}</h4>
                            <p className="video-text-secondary">{videos[currentVideoIndex]?.date}</p>
                            <div className="latest-video">
                                <img src="/icon/youtube play button.png" alt="Youtube Play Button" className="youtube-play-button-icon"/>
                                <p className="video-text-secondary">Latest video on youtube</p>
                                <div className="carousel-controls">
                                    {videos.map((_, index) => (
                                        <button
                                            key={index}
                                            className={`carousel-dot ${currentVideoIndex === index ? 'active' : ''}`}
                                            onClick={() => {
                                                setCurrentVideoIndex(index);
                                                setIsPlaying(false);
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="featured-projects">
                <div className="title">
                    <h2 className="text">Featured Projects</h2>
                </div>
                <div className="projects-grid">
                    {projects.slice(0, 9).map((project, index) => (
                        <div 
                            key={index} 
                            className="project-item"
                            onClick={() => handleProjectClick(project)}
                        >
                            <img src={project.poster} alt={project.title} className="project-image" />
                            <div className="overlay">
                                <h3 className="project-title">{project.title}</h3>
                                <div className="see-now">
                                    <span>See Now</span>
                                    <img 
                                        src="/icon/see now.png" 
                                        alt="See Now Icon" 
                                        className="icon"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="clients">
                <div className="title">
                    <h2 className="text">Our Clients</h2>
                </div>
                <div className="client-logos">
                    <div className="row-top">
                        {clients.slice(0, 3).map((client, index) => (
                            <img key={index} src={client.logo} alt={client.name} className="client-logo" />
                        ))}
                    </div>
                    <div className="row-bottom">
                        {clients.slice(3, 5).map((client, index) => (
                            <img key={index} src={client.logo} alt={client.name} className="client-logo" />
                        ))}
                    </div>
                </div>
            </div>
            <div className="news">
                <div className="title">
                    <h2 className="text">Featured News</h2>
                </div>
                <div className="all-news">
                    {news.slice(0, 3).map((newsItem, index) => (
                    <div 
                        key={index} 
                        className="news-item"
                        onClick={() => handleNewsClick(newsItem)}
                    >
                        <img src={newsItem.poster} alt={newsItem.title} className="image" />
                        <p className="date">Posted on <span>{newsItem.date}</span> / By {newsItem.publisher}</p>
                        <div className="content">
                            <h4 className="title">{newsItem.title}</h4>
                            <p className="desc">
                            {newsItem.desc.length > 100 
                                ? `${newsItem.desc.substring(0, 300)}...` 
                                : newsItem.desc}
                            </p>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Home;
