import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./components/pages/Home";
import AboutUs from "./components/pages/AboutUs";
import KelanaraPlus from "./components/pages/KelanaraPlus";
import News from "./components/pages/News";
import NewsDetails from "./components/pages/NewsDetails"; // Import the new component
import Contact from "./components/pages/Contact";
import Project from "./components/pages/Project";
import ProjectDetails from "./components/pages/ProjectDetails";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/kelanara" element={<KelanaraPlus />} />
        <Route path="/media" element={<News />} />
        <Route path="/media/:id" element={<NewsDetails />} /> 
        <Route path="/contact" element={<Contact />} />
        <Route path="/project" element={<Project />} />
        <Route path="/project/:id" element={<ProjectDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
