import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout";
import Footer from "../Footer";

const Project = () => {
    const [projects, setProjects] = useState([]);
    const [clients, setClients] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/data/projects.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(projects_data => {
                setProjects(projects_data);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });

        fetch('/data/clients.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(clients_data => {
                setClients(clients_data);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    }, []);

    const handleProjectClick = (projectItem) => {
        navigate(`/project/${projectItem.id}`, { state: { projectItem } }); // Redirect to the project details page
    };

    return (
        <>
            <Layout containerClass="project-container" />
            <div className="all-project-wrapper">
                <h2 className="title">All Kelanara Projects</h2>
                <div className="back-home-route">
                    <img src="/icon/back home.png" alt="back-home-icon" className="back-home-icon" />
                    <span onClick={() => navigate('/')} className="back-home">Back Home</span>
                </div>
            </div>
            <div className="all-project-grid">
                {projects.map((project, index) => (
                    <div
                        key={index}
                        className="project-item"
                        onClick={() => handleProjectClick(project)} // Navigate on click
                    >
                        <img src={project.poster} alt={project.title} className="project-image" />
                        <h3 className="project-title">{project.title}</h3>
                    </div>
                ))}
            </div>
            <div className="project-clients">
                <div className="project-clients-wrapper">
                    <div className="title">Our Clients</div>
                    <div className="all-clients">
                        {clients.map((client, index) => (
                            <div key={index} className="item">
                                <p className="client-name">{client.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Project;
