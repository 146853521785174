import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import Footer from "../Footer";
import PodcastPlayer from "../PodcastPlayer";
import { useNavigate } from "react-router-dom";
import '../../App.css';

// Main News component
const News = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [currentPodcastIndex, setCurrentPodcastIndex] = useState(0);
    const [videos, setVideos] = useState([]);
    const [news, setNews] = useState([]);
    const [podcasts, setPodcasts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const newsPerPage = 6;

    const navigate = useNavigate();

    const handleNewsClick = (newsItem) => {
        navigate(`/media/${newsItem.id}`, { state: { newsItem } }); // Pass data via state
    };

    useEffect(() => {
        fetch('/data/youtube-videos.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(videos_data => {
                setVideos(videos_data);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });

        fetch('/data/news.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(news_data => {
                setNews(news_data);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
            
        // Add podcast data fetch
        fetch('/data/podcasts.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(podcasts_data => {
                setPodcasts(podcasts_data);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    }, []);

    const handlePlay = () => {
        setIsPlaying(true);
    };

    const handleNext = () => {
        setCurrentVideoIndex((prev) => (prev + 1) % videos.length);
        setIsPlaying(false);
    };

    const handlePrev = () => {
        setCurrentVideoIndex((prev) => (prev - 1 + videos.length) % videos.length);
        setIsPlaying(false);
    };

    const handleNextPodcast = () => {
        setCurrentPodcastIndex((prev) => (prev + 1) % podcasts.length);
    };

    const handlePrevPodcast = () => {
        setCurrentPodcastIndex((prev) => (prev - 1 + podcasts.length) % podcasts.length);
    };

    // Calculate the news items for the current page
    const indexOfLastNews = currentPage * newsPerPage;
    const indexOfFirstNews = indexOfLastNews - newsPerPage;
    const currentNews = news.slice(indexOfFirstNews, indexOfLastNews);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate the total number of pages
    const totalPages = Math.ceil(news.length / newsPerPage);

    // Function to go to the next page
    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <>
            <Layout containerClass="media-container" />
            <div className="youtube-video">
                {/* Your existing video section */}
                <div className="video-wrapper">
                    {!isPlaying ? (
                        <div className="thumbnail-container">
                            <img 
                                src={`https://img.youtube.com/vi/${videos[currentVideoIndex]?.id}/maxresdefault.jpg`} 
                                alt="Video Thumbnail" 
                                className="video-thumbnail"
                            />
                            <div className="play-button" onClick={handlePlay}>
                                <img 
                                    src="/icon/play button.png" 
                                    alt="Play Button" 
                                    className="play-icon"
                                />
                            </div>
                        </div>
                    ) : (
                        <iframe
                            src={`https://www.youtube.com/embed/${videos[currentVideoIndex]?.id}?autoplay=1&modestbranding=1&showinfo=0&controls=1&rel=0&fs=1&color=white&disablekb=1&playsinline=1&iv_load_policy=3&enablejsapi=1`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            className="intro playing"
                        ></iframe>
                    )}
                    <div className="bottom-wrapper">
                        <div className="text-wrapper">
                            <h4 className="video-text">{videos[currentVideoIndex]?.title}</h4>
                            <p className="video-text-secondary">{videos[currentVideoIndex]?.date}</p>
                            <div className="latest-video">
                                <img src="/icon/youtube play button.png" alt="Youtube Play Button" className="youtube-play-button-icon"/>
                                <p className="video-text-secondary">Latest video on youtube</p>
                                <div className="carousel-controls">
                                    {videos.map((_, index) => (
                                        <button
                                            key={index}
                                            className={`carousel-dot ${currentVideoIndex === index ? 'active' : ''}`}
                                            onClick={() => {
                                                setCurrentVideoIndex(index);
                                                setIsPlaying(false);
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="news">
                {/* Your existing news section */}
                <div className="title">
                    <h2 className="text">Featured News</h2>
                </div>
                <div className="all-news">
                    {currentNews.map((newsItem, index) => (
                        <div 
                            key={index} 
                            className="news-item"
                            onClick={() => handleNewsClick(newsItem)}
                        >
                            <img src={newsItem.poster} alt={newsItem.title} className="image" />
                            <p className="date">Posted on <span>{newsItem.date}</span> / By {newsItem.publisher}</p>
                            <div className="content">
                                <h4 className="title">{newsItem.title}</h4>
                                <p className="desc">
                                {newsItem.desc.length > 100 
                                    ? `${newsItem.desc.substring(0, 300)}...` 
                                    : newsItem.desc}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="pagination">
                    {[...Array(totalPages)].map((_, index) => (
                        <button 
                            key={index} 
                            onClick={() => paginate(index + 1)}
                            className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button 
                        onClick={goToNextPage} 
                        className="pagination-button next-button"
                        disabled={currentPage >= totalPages}
                    >
                        Next <span><img src="/icon/right.png" alt="right arrow" /></span>
                    </button>
                </div>
            </div>
            <div className="podcast">
                <PodcastPlayer 
                    podcast={podcasts[currentPodcastIndex]}
                    onNext={handleNextPodcast}
                    onPrev={handlePrevPodcast}
                    currentIndex={currentPodcastIndex}
                />
            </div>
            <Footer />
        </>
    );
};

export default News;